import * as React from "react";
import { ChatWidget } from "@app/components/coach/chat/components/chat-widget";
import { ChatThread } from "@app/service/chat";
import { fmtMsg } from "@app/util";
import { GSAdminLocale } from "@app/locales/localeid";

interface IChatMessageViewProps {
    chatThread: ChatThread;
    chatUsers: string[];
}

export const ChatMessageView: React.FunctionComponent<IChatMessageViewProps> = props => {
    return props.chatThread && props.chatUsers && props.chatUsers.length === 2 ? (
        <div className="thread-message-view-content">
            <span>{`${fmtMsg({ id: GSAdminLocale.ChatThread })}: ${props.chatThread.title}`}</span>
            <div>
                <ChatWidget
                    key={props.chatThread.id}
                    chatThreadId={props.chatThread.id}
                    chatMessageId={props.chatThread && props.chatThread.messageId}
                    autofocus={false}
                    senderId={props.chatUsers[0]}
                    receiver={{ id: props.chatUsers[1] }}
                    searchable={false}
                    getBadgeCount={() => {}}
                    viewOnly={true}
                />
            </div>
        </div>
    ) : (
        <>
            <div className="thread-message-title" />
            <div className="thread-message-view" />
        </>
    );
};
