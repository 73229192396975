import * as React from "react";
import * as Utils from "./utils";
import { fmtMsg } from "@app/util";
import { MyChatStore } from "./store";
import { useService } from "@app/hooks";
import { withRouter } from "react-router";
import { TYPES } from "@app/service/types";
import { MainTitle } from "@app/components";
import { GSAdminLocale } from "@app/locales/localeid";
import { ChatThread, IChatService } from "@app/service/chat";
import { SelectBoxView } from "./component/chat-history/select-box-view";
import { ChatThreadSelect } from "./component/chat-select/chat-thread-select";
import { AsyncLoadList } from "./component/chat-select/async-load-list";
import { ChatMessageView } from "./component/chat-history/chat-message-view";
import "./style/index.less";

const keyMapping = (thread: ChatThread | any) => parseInt(thread.id);
const titleMapping = (thread: ChatThread | any) => thread.title;

export const ChatHistory: React.FunctionComponent = () => {
    const chatservice = useService<IChatService>(TYPES.IChatService);
    const fetchChatThreads = async (attenders: string[], schoolIds: string) =>
        chatservice.getChatThreadByAttenders({ attenders, schoolIds });

    const [selectedThread, setSelectedThread] = React.useState<ChatThread>(
        null
    );

    const [selectedUsers, setSelectedUsers] = React.useState<string[]>();
    const [selectedSchool, setSelectedSchool] = React.useState<string>();
    const [searchedThread, setSearchedThread] = React.useState<ChatThread>(
        null
    );
    const handleOnSelectedUserChange = (users: string[], schoolId: string) => {
        setSelectedUsers(users);
        setSelectedSchool(schoolId);
    };

    const handleOnSelectThread = (thread: ChatThread) => {
        setSelectedThread(thread);
    };

    const handleOnSearchThread = (thread: ChatThread) =>
        setSearchedThread(thread);

    React.useEffect(() => {
        setSearchedThread(null);
        setSelectedThread(null);
    }, [selectedUsers]);

    return (
        <MyChatStore>
            <div className="page-container content-layout">
                <MainTitle plain={fmtMsg({ id: GSAdminLocale.ChatHistory })} />
                <div className="page-content">
                    <div className="page-content-layout">
                        <SelectBoxView
                            handleOnSelectedUserChange={
                                handleOnSelectedUserChange
                            }
                        />
                        <div className="history-content-layout">
                            <div className="history-thread-select-layout">
                                <div className="history-thread-select">
                                    <ChatThreadSelect
                                        schoolId={selectedSchool}
                                        attenders={selectedUsers}
                                        handleOnSearchedThread={
                                            handleOnSearchThread
                                        }
                                        placeholder={fmtMsg({
                                            id:
                                                GSAdminLocale.ChatHistoryThreadSearch
                                        })}
                                        disabled={
                                            !selectedUsers ||
                                            selectedUsers.length === 0
                                        }
                                        withButton
                                    />
                                </div>
                                <div className="history-thread-list">
                                    <AsyncLoadList
                                        fetchFn={fetchChatThreads}
                                        deps={[selectedUsers, selectedSchool]}
                                        keyMapping={keyMapping as any}
                                        handleOnSelect={handleOnSelectThread}
                                        titleMapping={titleMapping}
                                        includeItem={searchedThread}
                                        updateItem={Utils.updateThread}
                                        autoClear={!selectedUsers}
                                    />
                                </div>
                            </div>
                            <div className="history-thread-content">
                                <ChatMessageView
                                    chatThread={selectedThread}
                                    chatUsers={selectedUsers}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </MyChatStore>
    );
};

export const ChatHistoryPage = withRouter(ChatHistory);
