import * as React from "react";
import { Select, Button } from "antd-min";
import { useService } from "@app/hooks";
import { IResourceService } from "@app/service/resources";
import { TYPES } from "@app/service/types";
import { RoleName } from "gl-commonui";
import { useAsyncFn } from "@app/hooks/useAsyncFn";
import { ISchoolService } from "@app/service/schools";
import { IRegionService } from "@app/service/admin/regions";
import "../../style/index.less";
import { fmtMsg } from "@app/util";
import { DashboardLocale, GSAdminLocale, SchoolLocale } from "@app/locales/localeid";

const viewableRoles = [
    RoleName.systemAdmin,
    RoleName.regionAdmin,
    RoleName.trainingAdmin,
    RoleName.trainingManager,
    RoleName.globalHead,
    RoleName.accountManager
];

const { Option } = Select;

interface ISelectBoxView {
    handleOnSelectedUserChange: (users: string[], schoolId: string) => void;
}

export const SelectBoxView: React.FunctionComponent<ISelectBoxView> = props => {
    const [selectedRegion, setSelectedRegion] = React.useState<string>(null);
    const [selectedSchool, setSelectedSchool] = React.useState<string>(null);
    const [selectedCoach, setSelectedCoach] = React.useState<string>(null);
    const [selectedTeacher, setSelectedTeacher] = React.useState<string>(null);

    const resourceservice = useService<IResourceService>(
        TYPES.IResourceService
    );
    const regionservice = useService<IRegionService>(TYPES.IRegionService);
    const schoolservice = useService<ISchoolService>(TYPES.ISchoolService);

    const fetchRegionResources = async () =>
        await regionservice.getAccessibleRegions();
    const fetchSchoolResources = async (regionId: string) =>
        await schoolservice.getAccessibleWithoutDisabled(regionId, false);

    const fetchChatSchoolsUsers = async (schoolId: string[]) =>
        await resourceservice.getChatSchoolsUsers({ schoolIds: [schoolId] });

    const [regions, asyncFetchRegionResources] = useAsyncFn<any>(
        fetchRegionResources,
        [viewableRoles]
    );
    const [schools, asyncFetchSchoolResources] = useAsyncFn<any>(
        fetchSchoolResources,
        [selectedRegion]
    );

    const [users, asyncFetchChatSchoolsUsers] = useAsyncFn<any>(
        fetchChatSchoolsUsers,
        [selectedSchool]
    );

    React.useEffect(() => {
        asyncFetchRegionResources(viewableRoles);
    }, [asyncFetchRegionResources]);

    React.useEffect(() => {
        selectedRegion && asyncFetchSchoolResources(selectedRegion);
    }, [asyncFetchSchoolResources]);

    React.useEffect(() => {
        selectedSchool && asyncFetchChatSchoolsUsers(selectedSchool);
    }, [asyncFetchChatSchoolsUsers]);

    React.useEffect(() => {
        setSelectedSchool(null);
    }, [selectedRegion]);

    React.useEffect(() => {
        setSelectedCoach(null);
        setSelectedTeacher(null);
    }, [selectedSchool]);

    React.useEffect(() => {
        if (selectedCoach && selectedTeacher) {
            props.handleOnSelectedUserChange(
                [selectedTeacher, selectedCoach],
                selectedSchool
            );
        } else {
            props.handleOnSelectedUserChange(null, selectedSchool);
        }
    }, [selectedCoach, selectedTeacher]);

    const handleOnSelectRegion = (key: string) => setSelectedRegion(key);
    const handleOnSelectSchool = (key: string) => setSelectedSchool(key);
    const handleOnSelectCoach = (key: string) => setSelectedCoach(key);
    const handleOnSelectTeacher = (key: string) => setSelectedTeacher(key);

    const renderRegion = React.useMemo(
        () =>
            regions.data &&
            regions.data.length !== 0 &&
            regions.data.map(data => (
                <Option key={data.id} value={data.id}>
                    <span title={data.englishName}>{data.englishName}</span>
                </Option>
            )),
        [regions]
    );

    const renderSchool = React.useMemo(
        () =>
            selectedRegion &&
            schools.data &&
            schools.data.map(data => (
                <Option key={data.id} value={data.id}>
                    <span title={data.name}>{data.name}</span>
                </Option>
            )),
        [schools, selectedRegion]
    );

    const renderCoach = React.useMemo(
        () =>
            selectedSchool &&
            users.data &&
            users.data
                .filter(u => u.roles.includes(RoleName.trainer))
                .map(user => (
                    <Option key={user.id} value={user.id}>
                        <span title={user.name}>{user.name}</span>
                    </Option>
                )),
        [users, selectedSchool]
    );

    const renderTeacher = React.useMemo(
        () =>
            selectedSchool &&
            users.data &&
            users.data
                .filter(u => u.roles.includes(RoleName.teacher))
                .map(user => (
                    <Option key={user.id} value={user.id}>
                        <span title={user.name}>{user.name}</span>
                    </Option>
                )),
        [users, selectedSchool]
    );

    return (
        <div className="select-box-layout">
            <div className="select-box-content">
                <div className="select-box-input-group">
                    <div className="select-box-content-cell">
                        <span>{fmtMsg({ id: GSAdminLocale.RegionSchoolsRegion })}:</span>
                        <Select
                            showSearch
                            optionFilterProp="label"
                            onChange={handleOnSelectRegion}
                            filterOption={(input, option) => {
                                const region = regions.data.find(
                                    r => r.id === option.key
                                );
                                return (
                                    region &&
                                    region.englishName &&
                                    region.englishName
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                )
                            }}
                            loading={regions.loading}
                        >
                            {renderRegion}
                        </Select>
                    </div>
                    <div className="select-box-content-cell">
                        <span>{fmtMsg({ id: SchoolLocale.CampusSchoolSelect })}:</span>
                        <Select
                            showSearch
                            optionFilterProp="label"
                            onChange={handleOnSelectSchool}
                            filterOption={(input, option) => {
                                const school = schools.data.find(
                                    s => s.id === option.key
                                );
                                return (
                                    school &&
                                    school.name &&
                                    school.name
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                );
                            }}
                            disabled={!selectedRegion || schools.loading}
                            value={selectedSchool}
                        >
                            {renderSchool}
                        </Select>
                    </div>
                </div>
                <div className="select-box-input-group">
                    <div className="select-box-content-cell">
                        <span>{fmtMsg({ id: SchoolLocale.ClassesColumnTrainerName })}:</span>
                        <Select
                            showSearch
                            optionFilterProp="label"
                            filterOption={(input, option) => {
                                const coach = users.data.find(
                                    u => u.id === option.key
                                );
                                return (
                                    coach &&
                                    coach.name &&
                                    coach.name
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                )
                            }}
                            disabled={
                                !selectedRegion ||
                                !selectedSchool ||
                                users.loading
                            }
                            onChange={handleOnSelectCoach}
                            value={selectedCoach}
                        >
                            {renderCoach}
                        </Select>
                    </div>
                    <div className="select-box-content-cell">
                        <span>{fmtMsg({ id: DashboardLocale.LandingTabTeacherText })}:</span>
                        <Select
                            showSearch
                            optionFilterProp="label"
                            filterOption={(input, option) => {
                                const teacher = users.data.find(
                                    u => u.id === option.key
                                );
                                return (
                                    teacher &&
                                    teacher.name &&
                                    teacher.name
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                )
                            }}
                            disabled={
                                !selectedRegion ||
                                !selectedSchool ||
                                users.loading
                            }
                            onChange={handleOnSelectTeacher}
                            value={selectedTeacher}
                        >
                            {renderTeacher}
                        </Select>
                    </div>
                </div>
            </div>
        </div>
    );
};
